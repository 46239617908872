.page {
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1280px;
  min-height: 100vh;
  margin: 0 auto;
}

.logo {
  display: block;
  width: 38px;
  height: 38px;
  color: #000;
  transition: opacity .3s ease-in-out;
}

.logo:hover {
  opacity: .7;
  cursor: pointer;
}