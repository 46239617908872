.search {
  padding: 80px 0 0;
  margin: 0 70px 80px;
}

.search-form {
  display: flex;
  margin: 0;
  padding: 0;
}

.search-form__container {
  margin: 0;
  padding: 0;
  height: 46.4px;
  border-radius: 50px;
  border: 1.5px solid #E8E8E8;
  max-width: 932px;
  width: 100%;
  display: flex;
  align-items: center;
}

.search-form__input {
  max-width: 845px;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 14.2px 0 14.2px 22px;
  margin: 0;
  border-radius: 50px;
}

.search-form__input:focus {
  outline: 1px solid #000;
}

.search-form__button {
  all: unset;
  max-width: 58.65px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  border-radius: 48px;
  background-color: #000;
  color: #fff;
  text-align: center;
  height: 34px;
  margin: 0 7px 0 21.35px;
  cursor: pointer;
  transition: opacity .3s ease-in-out;
}

.search-form__button:hover {
  cursor: pointer;
  opacity: .7;
}

.search__error {
  display: block;
  width: 100%;
  border-bottom: 1px solid #E8E8E8;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  height: 20px;
  margin: 0;
  padding: 10px 0 53px;
  color: red;
}

.search-form__input_error:focus {
  outline: 1px solid red;
}

.search-form__input_error::placeholder {
  color: red;
}

@media screen and (max-width: 920px) {
  .search {
    padding: 60px 0 0;
    margin: 0 30px 80px;
  }

  .search-form {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    row-gap: 38px;
  }

  .search-form__input {
    padding: 14.2px 0 14.2px 18px;
  }

  .search__error {
    text-align: center;
    padding-bottom: 27px;
  }
}

@media screen and (max-width: 360px) {
  .search {
    margin: 0 10px 50px;
  }

  .search-form {
    row-gap: 44px;
  }

  .search-form__input {
    font-size: 11px;
    padding-left: 16px;
  }

  .search__error {
    padding-bottom: 13px;
  }
}