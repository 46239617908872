@font-face {
  src: url(./inter/Inter-Medium.woff) format(woff),
       url(./inter/Inter-Medium.woff2) format(woff2);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url(./inter/Inter-Regular.woff) format('woff'),
       url(./inter/Inter-Regular.woff2) format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
}