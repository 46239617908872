.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.not-found__title {
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  max-width: 400px;
  text-align: center;
  width: 100%;
  margin: 0 auto 5px;
  padding: 0;
}

.not-found__text {
  margin: 0 auto 184px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.not-found__link {
  text-decoration: none;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  transition: opacity .3s ease-in-out;
}

.not-found__link:hover {
  cursor: pointer;
  opacity: .7;
}

@media screen and (max-width: 500px) {
  .not-found__title {
    font-size: 80px;
    line-height: 97px;
    margin-bottom: 10px;
  }
  
  .not-found__text {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 284px;
  }

  .not-found__link {
    font-size: 12px;
    line-height: 15px;
  }
}