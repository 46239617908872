.techs {
  background-color: #F5F5F5;
  margin: 0;
  padding: 100px 70px;
}

.techs__title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  border-bottom: 1px solid #000;
  margin: 0 0 90px;
  padding: 0 0 23px;
  letter-spacing: -0.04em;
}

.techs__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.techs__content_title {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin: 0 0 26px;
  padding: 0;
  letter-spacing: -0.04em;
}

.techs__content_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 100px;
  max-width: 460px;
  width: 100%;
  text-align: center;
  padding: 0;
  letter-spacing: -0.04em;
}

.techs__list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.techs__list_item {
  width: 90px;
  height: 60px;
  background-color: #E8E8E8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.techs__list_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
}

@media screen and (max-width: 920px) {
  .techs {
    padding: 90px 50px;
  }

  .techs__title {
    margin: 0 0 80px;
  }

  .techs__content_title {
    margin: 0 0 22px;
  }

  .techs__content_text {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 83px;
  }

  .techs__list_item {
    width: 84px;
    height: 57px;
  }

  .techs__list_text {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 750px) {
  .techs__list {
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .techs {
    background-color: unset;
    padding: 70px 18px;
  }

  .techs__list {
    max-width: 178px;
    justify-content: left;
  }

  .techs__content_title {
    font-size: 30px;
    line-height: 36px;
  }

  .techs__title {
    padding-bottom: 28px;
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 22px;
  }

  .techs__content_title {
    margin-bottom: 24px;
  }

  .techs__content_text {
    margin-bottom: 50px;
    font-size: 11px;
    line-height: 16px;
  }
}