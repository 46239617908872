.form {
  margin: 70px auto;
  max-width: 396px;
  width: 100%;
}

.form__title {
  margin: 40px auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  font-style: normal;
}

.form__set {
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.form__label {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #A0A0A0;
}

.form__input {
  margin: 10px auto 0;
  max-width: 366px;
  width: 100%;
  padding: 15px;
  border: none;
  background-color: #F9F9F9;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.form__input:focus {
  outline: 1px solid #000;
}

.form__input_password {
  margin-bottom: 5px;
}

.form__button {
  all: unset;
  max-width: 396px;
  width: 100%;
  background-color: #000;
  border-radius: 3px;
  color: #FFF;
  padding: 0;
  margin: 0 auto 16px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 45px;
  text-align: center;
  transition: opacity .3s ease-in-out;
}

.form__button:hover {
  cursor: pointer;
  opacity: .7;
}

.form__redirect {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A0A0A0;
  text-align: center;
  text-decoration: none;
  margin: 0;
  padding: 0 2px 0 3px;
}

.form__redirect_active {
  color: #000;
  transition: opacity .3s ease-in-out;
}

.form__redirect_active:hover {
  cursor: pointer;
  opacity: .7;
}

.form__input-error {
  padding: 4px 0;
  display: block;
  color: red;
  height: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.form__input-error_login {
  margin-bottom: 119px;
  padding-top: 5px;
}

.form__input-error_register {
  margin-bottom: 31px;
  padding-top: 5px;
}

.form__input_type-error::placeholder {
  color: #FF4062;
}

.form__input_type-error:focus {
  outline: 1px solid #FF4062;
}

.form__button_disabled {
  opacity: .6;
  pointer-events: none;
}

.form__input-error_submit {
  height: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  margin: 0 0 20px;
  color: #FF004C;
}

@media screen and (max-width: 920px) {
  .form {
    margin-top: 232px;
  }
}

@media screen and (max-width: 500px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
  }

  .form__title {
    margin: 50px auto 80px;
  }

  .form__button {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 14px;
  }

  .form__redirect {
    font-size: 12px;
    line-height: 15px;
  }

  .form__input {
    max-width: 230px;
  }

  .form__input-error_register {
    margin-bottom: 118px;
  }

  .form__container {
    max-width: 260px;
    width: 100%;
  }

  .form__input-error_login {
    margin-bottom: 207px;
  }
}